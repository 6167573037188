import React from "react"
import { Redirect } from "react-router-dom"

class AuthorizationWrapper extends React.Component {

    render() {
        if(!sessionStorage.getItem("LOGGED_IN")) {
            return <Redirect to="/login" />            
        }

        if(sessionStorage.getItem("IS_PORTAL_MANAGER") || 
           sessionStorage.getItem("IS_PORTAL_USER")) {
            return <Redirect to="/provider" />
        }
        
        return <>
            {this.props.children}
        </>
    }
}

export default AuthorizationWrapper