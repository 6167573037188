/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    td.coverage{
        padding-left: 15px;
    }

    input.limit{
        border: none;
        background: none;
        margin-top: 1px;
        padding-left: 11px;
    }
`
class Application extends React.Component{

    state={
        id: "",
        name: "",
        field1Type: "",
        field1Value: "",
        templateId: "",
        boolNoOnly: false,
        boolYesOnly: false,
        valid: true,
        message: ""
    }

    componentDidMount = async() =>{
        const { coverage, applicationsContainer, updateError } = this.props
        const cov = applicationsContainer.state.coveragesMap.get(coverage)

        let field1Value = ""

        if(cov.field1Type === "Dropdown"){
            field1Value = cov.field1Value ? cov.field1Value : "None"
        }else{
            field1Value = cov.field1Value ? cov.field1Value : ""
        }

        if(cov.field1Type === "Bool" && cov.boolNoOnly === true){
            field1Value = "false"
        }

        if(cov.field1Type === "Bool" && cov.boolYesOnly === true){
            field1Value = "true"
        }

        await this.setState({
            id: cov.id,
            name: cov.name,
            insuringAgreementLabel: cov.insuringAgreementLabel,
            templateId: cov.templateId,
            boolNoOnly: cov.boolNoOnly,
            boolYesOnly: cov.boolYesOnly,
            field1Type: cov.field1Type,
            field1Value: field1Value,
            message: cov.message     
        })

        if(cov.field1Type === "Bool" && cov.field1Value === "false"){
            updateError(cov.message)
        }
    }

    updateValue = async (e)=>{
        const { updateError } = this.props
        e = e.currentTarget
        let value = e.value
        
        if(e.name === "field1Value" && this.state.field1Type === "Bool"){
            if(value === "yes"){
                value = "true"
                updateError("")
            }else{
                value = "false"
                updateError(this.state.message)
            }
        }

        await this.setState({
            [e.name]: value
        })

        this.updateCoverage()
    }

    updateCoverage = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateCoverages(this.state)
    }
        
    render(){
        const { applicationsContainer, index } = this.props
        const isValidating = applicationsContainer.state.isValidating

        let fieldValue
        if(this.state.field1Type === "Dropdown"){
            fieldValue = applicationsContainer.state.coveragesMap.get(this.state.templateId) ?
                         applicationsContainer.state.coveragesMap.get(this.state.templateId).field1Value : "None"
        }else{
            fieldValue = applicationsContainer.state.coveragesMap.get(this.state.templateId) ?
                         applicationsContainer.state.coveragesMap.get(this.state.templateId).field1Value : ""
        }

        return(            
            <tr css={componentStyle}>
                <td className="coverage">{this.state.name}</td>
                <td>{this.state.insuringAgreementLabel}</td>
                {this.state.field1Type === "Dropdown" &&
                    <td>
                        <div className="form-group">
                            {(index === 0) &&
                            <select className={"form-control form-control-sm" + ((index === 0 && isValidating && this.state.field1Value === "None") ? " required" : "")}
                                    id="field1Value"
                                    name="field1Value"
                                    value={fieldValue ? fieldValue : "None"}
                                    onChange={this.updateValue}>                      
                                    <option value="None">{index === 0 ? "--- Select ---" : ""}</option>
                                    { applicationsContainer.state.coverageLimits.map((coverageLimit, index)=>(
                                        (coverageLimit.id !== "None" &&
                                            <option key={index}
                                                    value={coverageLimit.id}>
                                                {coverageLimit.name}
                                            </option>
                                        )                                        
                                    ))}
                            </select>
                            }
                            {(index > 0) &&
                                <input type="text" 
                                    className="form-control form-control-sm limit" 
                                    id="field1Value" 
                                    name="field1Value"
                                    value={applicationsContainer.state.valuesMap.get(fieldValue)}
                                    onChange={()=>{}}
                                    disabled={true}
                                    required />
                            }
                        </div>
                    </td>
                }                    
                {this.state.field1Type === "Bool" &&
                    <td>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                type="radio" 
                                id="field1ValueYes" 
                                name="field1Value"                              
                                value="yes"
                                checked={this.state.field1Value === "true"}
                                disabled={this.state.boolNoOnly || this.state.boolYesOnly}
                                onChange={this.updateValue} />
                            <label className={"form-check-label" + ((isValidating && this.state.field1Value === "") ?" required" : "")}
                                   htmlFor="field1ValueYes">
                                Yes
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" 
                                type="radio" 
                                id="field1ValueNo" 
                                name="field1Value"                                
                                value="no"
                                checked={this.state.field1Value === "false"}
                                disabled={this.state.boolNoOnly || this.state.boolYesOnly}
                                onChange={this.updateValue} />
                            <label className={"form-check-label" + ((isValidating && this.state.field1Value === "") ?" required" : "")}
                                   htmlFor="field1ValueNo">
                                No
                            </label>
                        </div>
                    </td>                        
                }                
            </tr>
        )
    }   
}

export default Application