import { sendGet, sendPost } from "./generic"


export const reorderAbovegroundStorageTanks = (payload)=> {
    return sendPost("AbovegroundStorageTanks/Reorder", payload)
}

export const getAbovegroundTankAges = ()=> {
    return sendGet("AbovegroundStorageTanks/TankAges")
}

export const getAbovegroundConstructions = ()=> {
    return sendGet("AbovegroundStorageTanks/Constructions")
}

export const getAbovegroundCapacities = ()=> {
    return sendGet("AbovegroundStorageTanks/Capacities")
}

export const getAbovegroundContents = ()=> {
    return sendGet("AbovegroundStorageTanks/Contents")
}

export const getAbovegroundDeductions = ()=> {
    return sendGet("AbovegroundStorageTanks/Deductibles")
}

export const updateAboveGroundStorageTank = (abovegroundStorageTankId, payload)=> {
    return sendPost("AbovegroundStorageTanks/"+abovegroundStorageTankId, payload)
}