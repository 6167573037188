/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
`

class Bind extends React.Component{

    state = {
        value: ""
    }

    componentDidMount = async()=>{
        const { applicationsContainer, question, item } = this.props

        this.setState({
            value: applicationsContainer.state.bindingQuestionsMap.get(item.id).get(question.bindingQuestionTemplateId).value
        })
    }

    updateValue = (e)=>{
        e = e.currentTarget
        this.setState({
            value: e.value
        })
    }

    setValue = (e)=>{
        e = e.currentTarget

        const { applicationsContainer } = this.props

        applicationsContainer.setBindingQuestion(e.getAttribute("data-binding-question-id"), 
                                                 e.getAttribute("data-binding-question-template-id"),
                                                 e.getAttribute("data-item-id"),
                                                 e.value)
    }

    render(){
        const { applicationsContainer, question, item } = this.props
        const isValidating = applicationsContainer.state.isValidating

        const bindingQuestionId = applicationsContainer.state.bindingQuestionsMap.get(item.id).get(question.bindingQuestionTemplateId).bindingQuestionId
        
        return(
            <input css={componentStyle}
                   type="text"
                   className={((isValidating && this.state.value === "") ? "required " : "") + "form-control form-control-sm"}
                   data-binding-question-template-id={question.bindingQuestionTemplateId}
                   data-binding-question-id={bindingQuestionId}
                   data-item-id={item.id}
                   value={this.state.value}
                   onChange={this.updateValue}
                   onBlur={this.setValue} />
        )
    }   
}

export default Bind