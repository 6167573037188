/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import GetQuote from "./GetQuote"
import Notifications from "./Notifications"
import HelpCenter from "./HelpCenter"
import Applications from "./Applications"
import Policies from "./Policies"
import Comments from "./Comments"

const componentStyle = css`
    margin-top: 15px;

    .row-flex{
        display: flex;
        margin-top: 15px;
    }

    .row-flex .col{
        flex: 1;
    }
`
class Home extends React.Component{    
    
    state = {
        commentsOpen: false
    }

    toggleComments = ()=>{
        this.setState({
            commentsOpen: this.state.commentsOpen ? false : true
        })
    }

    render(){
        const { notificationsContainer, applicationsContainer , accountsContainer} = this.props
        
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>Welcome!</h2>
                    </div>
                </div>
                <div className="row row-flex">
                    <div className="col col-sm-4">
                        <GetQuote applicationsContainer={applicationsContainer}/>
                    </div>
                    <div className="col col-sm-4">
                        <Notifications notificationsContainer={notificationsContainer}/>
                    </div>
                    <div className="col col-sm-4">
                        <HelpCenter />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Applications applicationsContainer={applicationsContainer}
                                       toggleComments={this.toggleComments}
                                       accountsContainer={accountsContainer}
                                       />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Policies applicationsContainer={applicationsContainer} />
                    </div>
                </div>

                {this.state.commentsOpen &&
                    <Comments applicationsContainer={applicationsContainer}
                              toggleComments={this.toggleComments} />
                } 
            </div>
        )
    }   
}

export default Home