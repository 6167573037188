/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { sortableContainer, SortableElement } from "react-sortable-hoc"
import UndergroundStorageTank from "./UndergroundStorageTank"
const SortableItem = SortableElement(UndergroundStorageTank);

const SortableContainer = sortableContainer(({children}) => {
    return <tbody id="sortableContainerInner">{children}</tbody>
})

const componentStyle = css`
    margin-top: 30px;

    table{
        margin-top: 10px;
    }

    p.footnote{
        margin: -15px 0 0 0;
    }

    .footnote-spacer{
        margin-right: 8px;
    }
`
class UndergroundStorageTanks extends React.Component{ 

    state = {
        file: null,
        fileName: "",
        loaded: false
    }

    componentDidMount = async ()=>{
        const { applicationsContainer } = this.props
        await applicationsContainer.getUndergroundStorageTanks()
        await this.setState({
            loaded: true
        })
    }

    getHelperContainer = ()=>{
        return document.querySelector('#sortableContainerInner')
    }

    addStorageTank = async ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.addUndergroundStorageTank()
    }

    onSortEnd = ({oldIndex, newIndex})=> {
        const { applicationsContainer } = this.props
        applicationsContainer.updateUndergroundStorageTankOrder(oldIndex, newIndex)
    }

    handleFileUpload = async (e)=>{
        e = e.currentTarget
        this.setState({
            file: e.files[0],
            fileName: e.files[0].name
        })
        e.value = null
    }

    uploadFile = async ()=>{
        const { applicationsContainer } = this.props

        if(this.state.file){
            await applicationsContainer.uploadUndergroundStorageTankFile(this.state.file)
        }

        this.setState({
            file: null,
            fileName: ""
        })
    }
        
    render(){
        const { applicationsContainer } = this.props

        return(
            <div css={componentStyle}>
                 <div className="row">
                    <div className="col">
                        <h3>Underground Storage Tank Schedule</h3>
                        <p className="directions">
                            <strong>Directions</strong>: Please complete table below for each underground storage tank which is being requested for coverage. Click here to download the Underground Storage Tank Schedule Excel template: <a href="/docs/UndergroundStorageTankSchedule.xlsm">UndergroundStorageTankSchedule.xlsm.</a>
                        </p>
                    </div>
                </div>
                <div className="row fileUpload margin-top">
                    <div className="col-sm-4">
                        <div className="form-group">     
                            <div className="custom-file float-left">
                                <input type="file" 
                                    className="custom-file-input form-control-sm" 
                                    id="file"
                                    name="file"
                                    onChange={this.handleFileUpload} />
                                <label className="custom-file-label col-form-label-sm" 
                                    htmlFor="file">
                                    {this.state.fileName ? this.state.fileName : "Choose File"}
                                </label>
                            </div>
                        
                            <button className="btn btn-sm btn-primary float-left upload"
                                    onClick={this.uploadFile}>
                                Upload
                            </button>
                        </div>
                    </div>
                    <div className="col-12 macro-direction">
                        <p>Please be sure your Excel settings are set to "Enable all macros" before pulling the spreadsheet to ensure all information uploads properly</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                    <table className="table table-sm table-striped sortable-table">
                            <thead>
                                <tr>
                                    <th className="action"></th>
                                    <th>Location<span className="required">*</span></th>
                                    <th>Tank Age (years)<span className="required">*</span></th>
                                    <th>Construction<span className="required">*</span></th>
                                    <th>Capacity (gallons)<span className="required">*</span></th>
                                    <th>Contents<span className="required">*</span></th>
                                    <th>Type of Leak Detection<span className="required">*</span></th>
                                    <th>Retroactive Date on Existing Policy&nbsp;†<span className="required">*</span></th>
                                    <th>Deductible<span className="required">*</span></th>
                                    <th>Tank Tightness Test Passed within last 12 months ††<span className="required">*</span></th>
                                    <th>Line Construction<span className="required">*</span></th>
                                    <th>Pipe Age<span className="required">*</span></th>
                                    <th>Leak Detect<span className="required">*</span></th>
                                    <th className="action">Clone</th>
                                    <th className="action">Del</th>
                                </tr>
                            </thead>
                            {this.state.loaded &&
                                <SortableContainer onSortEnd={this.onSortEnd}
                                                   lockAxis="y"
                                                   useDragHandle
                                                   lockToContainerEdges
                                                   helperContainer={this.getHelperContainer}
                                                   helperClass="sortable-body">
                                    {applicationsContainer.state.undergroundStorageTanks.map((undergroundStorageTank, index)=>(     
                                        <SortableItem key={undergroundStorageTank}
                                                    index={index}
                                                    undergroundStorageTank={undergroundStorageTank}
                                                    applicationsContainer={applicationsContainer} />
                                                    ))}   
                                </SortableContainer>    
                            }
                                 
                        </table>
                        <p className="footnote"><span className="footnote-spacer"></span>† Use inception date for proposed policy if no prior coverage or if retroactive date was not applicable on prior policy.<br />
                        †† The referenced tank tightness testing can either be conducted by a third-party or completed on the ATG/Interstitial monitor in place for the UST.</p>
                        <button className="btn btn-sm btn-primary margin-top"
                                onClick={this.addStorageTank}>
                            Add Underground Storage Tank
                        </button>
                    </div>
                </div>                
            </div>
        )
    }   
}

export default UndergroundStorageTanks