/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css` 
    select.named-insured{
        width: 350px;
    }
`

class SelectNamedInsured extends React.Component{

    state = {
        value: "",
        selectedNamedInsuredId: ""
    }

    componentDidMount = async ()=>{
        const { applicationsContainer, selectedNamedInsured } = this.props
        this.setState({
            value: applicationsContainer.state.selectedNamedInsuredsMap.get(selectedNamedInsured).selectedNamedInsuredId,
            state: selectedNamedInsured
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        const { applicationsContainer } = this.props
       
        applicationsContainer.setSelectedNamedInsured(this.state.state, e.value)
        
        this.setState({
            value: e.value
        })
    }

    render(){
        const { applicationsContainer, selectedNamedInsured } = this.props
        const isValidating = applicationsContainer.state.isValidating

        const sni = applicationsContainer.state.selectedNamedInsuredsMap.get(selectedNamedInsured)

        return(            
            <tr css={componentStyle}>
                <td>{sni.state}</td>
                <td>
                    <select className={((isValidating && (this.state.value === "" || this.state.value === "00000000-0000-0000-0000-000000000000")) ? "required " : "") + "form-control form-control-sm named-insured"}
                            value={this.state.value}
                            onChange={this.updateValue}>
                        <option value="00000000-0000-0000-0000-000000000000"
                                disabled={(this.state.value !== "" && this.state.value !== "00000000-0000-0000-0000-000000000000")}>
                            Select
                        </option>
                        {sni.namedInsureds.map((namedInsured, index) =>(
                            <option key={index}
                                    value={namedInsured.id}>
                                {namedInsured.name}
                            </option>
                        ))}
                    </select>
                </td>
            </tr>
        )
    }
}

export default SelectNamedInsured