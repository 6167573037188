/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import BindingQuestionField from "./BindingQuestionField"

const componentStyle = css`
`
class AbovegroundBindingQuestionRow extends React.Component{

    setValue = (e)=>{
        e = e.currentTarget

        const { applicationsContainer } = this.props

        applicationsContainer.setBindingQuestion(e.getAttribute("data-binding-question-id"), 
                                                 e.getAttribute("data-binding-question-template-id"),
                                                 e.getAttribute("data-item-id"),
                                                 e.value)
    }

    render(){
        const { applicationsContainer, tank } = this.props
        const valuesMap = applicationsContainer.state.valuesMap;

        return(            
            <tr css={componentStyle}>
                <td>{tank.location.address}</td>
                <td>{valuesMap.get(tank.tankAge)}</td>
                <td>{valuesMap.get(tank.construction)}</td>
                <td>{tank.capacityInNumber}</td>
                <td>{valuesMap.get(tank.contents)}</td>
                {tank.questions.map((question, index)=>(
                    <td key={index}>
                        <BindingQuestionField applicationsContainer={applicationsContainer}
                                              question={question}
                                              item={tank} />
                    </td>
                ))}
            </tr>
        )
    }   
}

export default AbovegroundBindingQuestionRow