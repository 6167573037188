/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { css } from "@emotion/react/macro"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { byPrefixAndName } from '@awesome.me/kit-de80aecb6a/icons'

const componentStyle = css`
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.7);

    .module {
        width: 80%;
        height: calc(80% - 100px);
        margin-left: 10%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        border-radius: 10px;
        padding: 30px;
        text-align: left;
        border: solid 1px blue;
    }


    .module.sm{
        width: 400px !important;
        height: 200px !important;
        margin-left: calc(50% - 200px);
    }


    .content{
        margin-top: 15px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
    }

    button.close{
        position: absolute;
        top: 15px;
        right: 15px;
    }

`

class Modal extends Component {

    render() {
        const { children, close, size, hideClose } = this.props
        return(

            <div css={componentStyle}>    
                <div className={"module " + size}>
                    {!hideClose &&
                        <button className="float-right close"
                        onClick={close}>
                            <i className="fa fa-times" aria-hidden="true"/>
                            {/* <FontAwesomeIcon icon={byPrefixAndName("fas", "times")} /> */}
                    </button>
                    }
                    
                    <div className="content">
                        {children}
                    </div>                   
                </div>
            </div>
        )
    }
}

export default Modal
