/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import UndergroundBindingQuestionRow from "./UndergroundBindingQuestionRow"

const componentStyle = css`
    margin-top: 15px;

    th.loc{
        width: 100px;
    }

    th.age{
        width: 100px;
    }

    th.construction{
        width: 150px;
    }

    th.capacity{
        width: 100px;
    }

    th.contents{
        width: 125px;
    }
`
class UndergroundBindingQuestionTable extends React.Component{

    render(){
        const { applicationsContainer, questionSet } = this.props
        
        return(
            <div css={componentStyle}>
                <table className="table table-sm">
                    <thead>
                        <tr>
                        <th className="loc">Location</th>
                            <th className="age">Tank Age</th>
                            <th className="construction">Construction</th>
                            <th className="capacity">Capacity</th>
                            <th className="contents">Contents</th>
                            {questionSet.tanks[0].questions.map((question, index)=>(
                                <th key={index}>
                                    {question.label}<span className="required">*</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {questionSet.tanks.map((tank, index)=>(
                            <UndergroundBindingQuestionRow key={index}
                                                           applicationsContainer={applicationsContainer}
                                                           tank={tank} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }   
}

export default UndergroundBindingQuestionTable