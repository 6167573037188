/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"
import { Link } from "react-router-dom"
import history from "../../components/History"

const componentStyle = css`
    h2{
        margin-bottom: 15px;
    }
`
class Login extends React.Component{

    state={
        username: "",
        password: "",
        error: ""
    }

    componentDidMount(){
        if(sessionStorage.getItem("LOGGED_IN")){
            history.push("/")
        }
    }

    updateValue = (e)=>{
        e = e.currentTarget
        this.setState({
            [e.name]: e.value
        })
    }

    login = async ()=>{
        const { authenticationContainer } = this.props
        const result = await authenticationContainer.login(this.state.username, this.state.password)

        if(result !== "succeeded"){
            await this.setState({
                error: result
            })
        }        
    }
        
    render(){
        const { authenticationContainer } = this.props

        return(        
            <>    
            <div className="row" css={componentStyle}>
                <div className="col">
                    <h2>Login</h2>
                    <p>This is the login screen for Great American’s TankAssure quoting system (the "System"). Proprietary and confidential information of Great American Insurance Company and its affiliates (the "Company") is stored, transmitted, and processed by the System. To access the System you, on behalf of yourself and any organization or company you represent ("You" or "Your"), must (i) enter your User ID and Password at the login screen; (ii) have read the System terms and conditions of use ("Access Agreement"); and (iii) agree to the Access Agreement by clicking the "Login" box.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input type="text" 
                                className="form-control form-control-sm" 
                                id="username" 
                                name="username"
                                value={this.state.username}
                                onChange={this.updateValue} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input type="password" 
                                className="form-control form-control-sm" 
                                id="password" 
                                name="password"
                                value={this.state.password}
                                onChange={this.updateValue} />
                    </div>
                </div>
            </div>

            {this.state.error !== "" &&
                <div className="row">
                    <div className="col-4">
                        <p className="error">{this.state.error}</p>
                    </div>
                </div>
            }

            <div className="row button-group">
                <div className="col-4">
                <button className="btn btn-sm btn-primary float-right"
                            onClick={this.login}
                            disabled={authenticationContainer.state.isLoading}>Login</button>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Link to="/forgot-password">Forgot Password?</Link>
                </div>
            </div>
            </> 
        )
    }   
}

export default Login