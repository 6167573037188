/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"

const componentStyle = css`
    margin-top: 15px;

    .signature{
        font-family: 'Sacramento', cursive;
        font-size: 28px;
        line-height: 28px;
        border: 0;
        width: 100%;
        outline: 0;
        background: none;
        padding-left: 10px;
    }

    .signature-line{
       height: 1px;
       background-color: #ccc;
       width: 100%;
       margin-top: -25px;
       display: block;
    }
`
class Signature extends React.Component{

    state ={
        name: "",
        title: "",
        signature: ""
    }

    componentDidMount(){       
        const { applicationsContainer } = this.props
        const signature = applicationsContainer.state.signature

        if(signature){
            this.setState({
                name: signature.name ? signature.name : "",
                title: signature.title ? signature.title : "",
                signature: signature.signature ? signature.signature : ""
            })
        }
    }
    
    updateValue = async (e)=>{
        e = e.currentTarget

        await this.setState({
            [e.name]: e.value
        })
    }

    setDate = async (date, name)=>{
        await this.setState({
            [name]: date
        })

        this.updateSignature()
    }

    updateSignature = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateSignature(this.state)
    }

    render(){
        const { applicationsContainer, bind } = this.props
        const isValidating = applicationsContainer.state.isValidating
        return(            
            <div css={componentStyle}>
                <div className="row">
                    <div className="col-sm-4">
                        <h3>Sign</h3>                        
                            <p className="directions">
                                {!bind &&
                                <>
                                Please enter the signature information for the authorized agent submitting this application. By inputting your information in this signature section you are consenting to an e-signature to complete this application.
                                </>
                                }
                            </p>
                        <div className="form-group">
                            <label htmlFor="name"
                                className={(isValidating && !this.state.name) 
                                    ? "required" : ""}>
                                Name:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                className="form-control form-control-sm" 
                                id="name" 
                                name="name"
                                value={this.state.name}
                                onChange={this.updateValue}
                                onBlur={this.updateSignature}
                                required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="title"
                                className={(isValidating && !this.state.title) 
                                    ? "required" : ""}>
                                Title:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                className="form-control form-control-sm" 
                                id="title" 
                                name="title"
                                value={this.state.title}
                                onChange={this.updateValue}
                                onBlur={this.updateSignature}
                                required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="signature"
                                className={(isValidating && !this.state.signature) 
                                    ? "required" : ""}>
                                Sign (type your name):<span className="required">*</span>
                            </label>
                            <input type="signature" 
                                   className="signature" 
                                   id="signature" 
                                   name="signature"
                                   value={this.state.signature}
                                   onChange={this.updateValue}
                                   onBlur={this.updateSignature}
                                   required />
                        </div>
                        <div className="signature-line" />                      
                    </div>
                </div>                    
            </div>
        )
    }   
}

export default Signature