/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Entity from "./Entity"


const componentStyle = css`
`
class Entities extends React.Component{ 

    state = {
        requestedCertificateForFinancialAssurance: true,
        noticeOfCancellationEntity1: "string",
        noticeOfCancellationEntity1Address: "string",
        noticeOfCancellationEntity2: "string",
        noticeOfCancellationEntity2Address: "string",
        tria: true
    }

    addEntity = async ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.addEntity()
    }
        
    render(){
        const { applicationsContainer } = this.props
        
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        {applicationsContainer.state.entity1 &&
                            <>
                            <table className="table table-sm table-striped sortable-table">
                                <thead>
                                    <tr>
                                        <th>Entity Name<span className="required">*</span></th>
                                        <th>Mailing Address<span className="required">*</span></th>
                                        <th className="action"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applicationsContainer.state.entity1 &&
                                        <Entity applicationsContainer={applicationsContainer}
                                                index={1}
                                                entity={applicationsContainer.state.entity1} />
                                    }
                                    {applicationsContainer.state.entity2 &&
                                        <Entity applicationsContainer={applicationsContainer}
                                                index={2}
                                                entity={applicationsContainer.state.entity2} />
                                    }                                    
                                </tbody>
                            </table>
                            <div className="sortable-table-border-bottom-fix" />
                            </>
                        }
                        {(!applicationsContainer.state.entity1 || !applicationsContainer.state.entity2) &&
                            <button className="btn btn-sm btn-primary"
                                    onClick={this.addEntity}>
                                Add Entity
                            </button>
                        }
                    </div>                    
                </div>
            </div>
        )
    }   
}

export default Entities