/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Coverage from "./Coverage"

const componentStyle = css`
    margin-top: 15px;

    table{
        margin-top: 10px;
    }

    p.note{
        margin-bottom: 4px;
    }

    p.coverageError{
        float: none;
        text-align: left;
        margin-top: -4px;
        margin-bottom: 4px;
    }
`
class Coverages extends React.Component{ 

    state = {
        loaded: false,
        limit: "None",
        error: ""
    }

    componentDidMount = async ()=>{
        const { applicationsContainer } = this.props
        await applicationsContainer.getCoverages()
        await this.setState({
            loaded: true
        })
    }

    updateError = (error)=>{
        this.setState({
            error: error
        })
    }

    render(){
        const { applicationsContainer } = this.props

        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <table className="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>Coverages</th>
                                    <th>Insuring Agreement</th>
                                    <th>Each Storage Tank Incident/Aggregate Limit<span className="required">*</span></th>
                                </tr>
                            </thead>
                            <tbody>

                                {(this.state.loaded && applicationsContainer.state.coveragesMap.size > 0) &&
                                    (applicationsContainer.state.coverages.map((coverage, index)=>(
                                        <Coverage key={index}
                                                  index={index}
                                                  coverage={coverage}
                                                  applicationsContainer={applicationsContainer}
                                                  updateError={this.updateError} />
                                    )))
                                }                                
                            </tbody>
                        </table>
                    </div>
                </div>
               
                <div className="row">
                    <div className="col">
                        {this.state.error &&
                            <p className="error coverageError">{this.state.error}</p>
                        }                        
                        <p className="note"><strong>Please Note</strong>: Defense Expense Limits must be selected as "yes" if a financial assurance certificate is required. If defense expense limits are selected, the limits will match the limits of liability selected for Coverage A and B up to a maximum of $2,500,000. Defense Expense Limits must be selected in the following states: KS, MT, VT, WY, and CT.</p>
                    </div>
                </div>
            </div>
        )
    }   
}

export default Coverages