/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"

const componentStyle = css`
    h2{
        margin-bottom: 15px;
    }
`
class ForgotPassword extends React.Component{

    state={
        username: "",
        error: "",
        emailSent: false
    }

    updateValue = (e)=>{
        e = e.currentTarget
        this.setState({
            [e.name]: e.value,
            error: false
        })
    }

    forgotPassword = async ()=>{
        const { authenticationContainer } = this.props
        const response = await authenticationContainer.forgotPassword(this.state.username)

        if(response !== true){
            this.setState({
                error: response
            })
        }else{
            this.setState({
                emailSent: true
            })
        }
    }
        
    render(){
        const { authenticationContainer } = this.props

        return(        
            <> 
            {!this.state.emailSent &&
                <>
                <div className="row" css={componentStyle}>
                    <div className="col">
                        <h2>Forgot Your Password</h2>
                        <p className="directions">Enter your email address to have your password reset link emailed to you.</p>
                    </div>
                </div>
                <div className="row" css={componentStyle}>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="username">Email Address:</label>
                            <input type="email" 
                                className="form-control form-control-sm" 
                                id="username" 
                                name="username"
                                value={this.state.username}
                                onChange={this.updateValue} />
                        </div>
                    </div>
                </div>
                {this.state.error !== "" &&
                    <div className="row">
                        <div className="col-sm-4">
                            <p className="error">{this.state.error}</p>
                        </div>
                    </div>
                }
                <div className="row button-group">
                    <div className="col-sm-4">
                        <button className="btn btn-sm btn-primary float-right"
                                onClick={this.forgotPassword}
                                disabled={authenticationContainer.state.isLoading}>Submit</button>
                    </div>
                </div>  
                </>
                }
                {this.state.emailSent &&
                    <div className="row" css={componentStyle}>
                    <div className="col">
                        <h2>Forgot Your Password</h2>
                        <p className="directions">If your account was found an email was sent with instructions on resetting your password.</p>
                    </div>
                </div>
                }
                        
            </> 
        )
    }   
}

export default ForgotPassword