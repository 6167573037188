/** @jsxImportSource @emotion/react */
import React from "react"
import { Router, Route, Switch } from "react-router-dom"
import { Subscribe } from "unstated"
import history from "../components/History"
import Home from "./Home/"
import Application1 from "./Application/Application1"
import Application2 from "./Application/Application2"
import Application3 from "./Application/Application3"
import ApplicationStatus from "./Application/ApplicationStatus"
import Bind from "./Application/Bind"
import Settings from "./Settings"
import AuthenticationWrapper from "./Authentication/AuthenticationWrapper"
import AccountsContainer from "../state/AccountsContainer"
import NotificationsContainer from "../state/NotificationsContainer"
import ApplicationsContainer from "../state/ApplicationsContainer"

class Dashboard extends React.Component{ 
        
    render(){
        return(
            <AuthenticationWrapper>
                <Subscribe to={[AccountsContainer, 
                                NotificationsContainer,
                                ApplicationsContainer]}>
                              {(accountsContainer, 
                                notificationsContainer,
                                applicationsContainer) =>
                    
                        <Router history={history}>
                            <Switch>
                                <Route path="/" 
                                       exact
                                       render={()=> <Home 
                                        notificationsContainer={notificationsContainer}
                                        applicationsContainer={applicationsContainer}
                                        accountsContainer={accountsContainer}
                                         />} />
                                <Route path="/application/1" 
                                       exact
                                       render={()=> <Application1 
                                        applicationsContainer={applicationsContainer} 
                                        accountsContainer={accountsContainer} />} />
                                <Route path="/application/2" 
                                       exact
                                       render={()=> <Application2 
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/application/3" 
                                       exact
                                       render={()=> <Application3
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/application/bind" 
                                       exact
                                       render={()=> <Bind 
                                        applicationsContainer={applicationsContainer}/>} />
                                <Route path="/application/status" 
                                       exact
                                       render={()=> <ApplicationStatus 
                                        applicationsContainer={applicationsContainer} />} />
                                <Route path="/settings" 
                                       exact
                                       render={()=> <Settings accountsContainer={accountsContainer}/>} />
                                <Route path="/*" 
                                       exact
                                       render={()=> <Home
                                        notificationsContainer={notificationsContainer}
                                        applicationsContainer={applicationsContainer}
                                        accountsContainer={accountsContainer} />} />
                            </Switch>                                
                        </Router>
                }
                </Subscribe>  
            </AuthenticationWrapper>                  
        )
    }   
}

export default Dashboard