/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import SelectNameInsured from "./SelectNameInsured"

const componentStyle = css`
`
class SelectNamedInsureds extends React.Component{
    render(){
        const { applicationsContainer } = this.props
        
        return(            
            <div css={componentStyle}>
               <div className="row">
                    <div className="col">
                        <h3>Select Primary Named Insured</h3>
                        <p className="directions">The following information is required to complete the requested certificate for financial assurance.</p>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>State</th>
                                    <th>Primary Named Insured<span className="required">*</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {applicationsContainer.state.selectedNamedInsureds.map((selectedNamedInsured, index) =>(
                                    <SelectNameInsured key={index}
                                                    applicationsContainer={applicationsContainer}
                                                    selectedNamedInsured={selectedNamedInsured} />
                                ))}
                            </tbody>                            
                        </table>
                    </div>
                </div>            
            </div>
        )
    }   
}

export default SelectNamedInsureds