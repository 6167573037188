/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    i{
        cursor: pointer;
    }

    textarea{
        margin-bottom: 3px;
    }
`
class Entity extends React.Component{

    state ={
        index: null,
        name: "",
        mailingAddress: ""
    }

    componentDidMount(){
        const { entity, index } = this.props
        
        this.setState({
           index: index,
           name: entity.name,
           mailingAddress: entity.mailingAddress
       })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        await this.setState({
            [e.name]: e.value
        })
    }

    updateEntity = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateEntity(this.state)
    }

    deleteEntity = async ()=>{
        const { applicationsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete entity " + this.state.name + "?")
        if(confirm){
           applicationsContainer.deleteEntity(this.state.index)
        }
    }
        
    render(){
        const { applicationsContainer } = this.props
        const isValidating = applicationsContainer.state.isValidating
        return(            
            <tr css={componentStyle}>
                <td>
                    <div className="form-group">
                        <input type="text" 
                                className={"form-control form-control-sm" + ((isValidating && this.state.name === "") ? " required" : "")}
                                id="name" 
                                name="name"
                                onChange={this.updateValue}
                                onBlur={this.updateEntity}
                                value={this.state.name}
                                placeholder="Enter Name" />
                    </div>
                </td>
                <td>
                    <textarea className={"form-control form-control-sm" + ((isValidating && this.state.mailingAddress === "") ? " required" : "")}
                              id="mailingAddress"
                              name="mailingAddress"
                              placeholder="Enter Mailing Address"
                              rows="2"
                              value={this.state.mailingAddress}
                              onChange={this.updateValue}
                              onBlur={this.updateEntity}>
                    </textarea>
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                       onClick={this.deleteEntity} />
                </td>
            </tr>
        )
    }   
}

export default Entity