/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    text-align: left;

    p{
        margin-bottom: 0;
    }
`
class Notification extends React.Component{ 
        
    render(){
        const { message } = this.props
        return(
            <div className="notification"
                 css={componentStyle}>
                <p>{message}</p>
            </div>
        )
    }   
}

export default Notification