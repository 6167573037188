/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"
import history from "../../components/History"
import { testValidPassword } from "../../helpers/ValidatePassword"

const componentStyle = css`
    h2{
        margin-bottom: 15px;
    }

    h3{
        margin-top: 15px;
        margin-bottom: 15px;
    }
`
class CompleteRegistration extends React.Component{

    state={
        password: "",
        confirmPassword: "",
        identityCode: "",
        code: "",
        registrationComplete: false,
        validation: false,
        error: ""
    }

    componentDidMount(){
        const { registrationsContainer } = this.props
        registrationsContainer.setState({
            completeRegistrationUp: true
        })

        this.setState({
            identityCode: window.location.pathname.split("/")[2],
            code: window.location.pathname.split("/")[3]
        })
    }

    componentWillUnmount(){
        const { registrationsContainer } = this.props
        registrationsContainer.setState({
            completeRegistrationUp: false
        })
    }

    login = ()=>{
        history.push("/login")
    }

    updateValue = (e)=>{
        e = e.currentTarget
        let value = e.value

        this.setState({
            [e.name]: value
        })

        if(this.state.isValidating){
            this.validateForm()
        }
    }

    completeRegistration = async ()=>{
        if(!await this.validateForm()){
            return
        }
        
        const { authenticationContainer } = this.props
        const result = await authenticationContainer.resetPassword(this.state)
        
        this.setState({
            registrationComplete: result
        })
    }

    validateForm = ()=>{
        this.setState({
            isValidating: true
        })

        const notRequired = [
            "registrationComplete",
            "code",
            "validation",
            "error"
        ]

        let valid = true
        let error = "Please complete all fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }
        
        if(this.state.password && !this.state.confirmPassword){
            valid = false
        }
        
        if(valid && this.state.password){
            if(!testValidPassword(this.state.password)){
                valid = false
                error = "Passwords must be 8 OR MORE characters, contain an uppercase character, a lowercase character, a digit, and a non-alphanumeric character"
            }
        }

        if(valid && this.state.password){
            if(this.state.password !== this.state.confirmPassword){
                valid = false
                error = "Passwords do not match"
            }
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }       
    }
        
    render(){
        const { authenticationContainer } = this.props
        return(

            <div css={componentStyle}>
                {!this.state.registrationComplete &&
                    <>
                    <div className="row" css={componentStyle}>
                        <div className="col">
                            <h2>Your Enrollment as a User for TankAssure Has Been Approved!</h2>
                            
                            <p className="margin-top">Please proceed with creating your personalized password.</p>

                            <p>Please note your password must:</p>
                            <ul>
                                <li>Be 8 or more characters</li>
                                <li>Contain an uppercase character</li>
                                <li>Contain a lowercase character</li>
                                <li>Contain a number</li>
                                <li>Contain a non-alphanumeric character</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="password"
                                        className={(this.state.isValidating && !testValidPassword(this.state.password)) 
                                            ? "required" : ""}>
                                    Password:<span className="required">*</span>
                                </label>
                                <input type="password" 
                                        className="form-control form-control-sm" 
                                        id="password" 
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.updateValue} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword"
                                       className={
                                        (this.state.isValidating) &&
                                        ((this.state.password !== this.state.confirmPassword) ||
                                        !this.state.confirmPassword)                                        
                                        ? "required" : ""}>
                                    Confirm Password:<span className="required">*</span>
                                </label>
                                <input type="password" 
                                        className="form-control form-control-sm" 
                                        id="confirmPassword" 
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.updateValue} />
                            </div>
                        </div>
                    </div>

                    {this.state.error !== "" &&
                        <div className="row">
                            <div className="col-sm-4">
                                <p className="error">{this.state.error}</p>
                            </div>
                        </div>
                    }

                    <div className="row button-group">
                        <div className="col-sm-4">
                        <button className="btn btn-sm btn-primary float-right"
                                onClick={this.completeRegistration}
                                disabled={authenticationContainer.state.isLoading}>Submit</button>
                        </div>
                    </div>
                    </>                }

                {this.state.registrationComplete &&
                    <div className="row" css={componentStyle}>
                        <div className="col">
                            <h2>Registration Complete!</h2>
                            <p>Please log in</p>
                            <button className="btn btn-sm btn-primary"
                                    onClick={this.login}>
                                Login
                            </button>
                        </div>
                    </div>
                }

            </div> 
        )
    }   
}

export default CompleteRegistration