import { sendPost } from "./generic"

export const addNamedInsured = (payload)=>{
    return sendPost("NamedInsureds", payload)
}

export const updateNamedInsured = (insuredId, payload)=> {
    return sendPost("NamedInsureds/"+insuredId, payload)
}

export const assignTankOwner = (insuredId, payload)=> {
    return sendPost("NamedInsureds/"+insuredId+"/IsTankOwner", payload)
}
