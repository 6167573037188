/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import AdditionalInsureds from "./AdditionalInsureds"
import Entities from "./Entities"
import Modal from "../../components/Modal"

const componentStyle = css`
    button.tria{
        margin-top: 4px;
    }

    h3.disclosure{
        margin-top: 0;
    }

    .tria-button-group{
        margin-top: 15px;
    }

    .tria-button-group button{
        margin-right: 20px;
    }

    p.electedStatus{
        margin-top: 5px;
    }

    p.endorsementError{
        float: none;
        text-align: left;
        margin-top: -4px;
        margin-bottom: 10px;
    }
`
class EndorsementQuestions extends React.Component{ 

    state = {
        requestedCertificateForFinancialAssurance: null,
        tria: null,
        triaOpen: false
    }

    componentDidMount = async ()=>{
        const { applicationsContainer } = this.props
        const ca = applicationsContainer.state

        await this.setState({
            requestedCertificateForFinancialAssurance: (ca.requestedCertificateForFinancialAssurance === true || ca.requestedCertificateForFinancialAssurance === "yes") ? "yes" : (ca.requestedCertificateForFinancialAssurance === false || ca.requestedCertificateForFinancialAssurance === "no") ? "no" : null,
            tria: (ca.tria === true || ca.tria === "yes") ? "yes" : (ca.tria === false || ca.tria === "no") ? "no" : null
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget
        
        await this.setState({
            [e.name]: e.value
        })

        this.updateEndorsementQuestion()
    }

    updateEndorsementQuestion = async ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateEndorsementQuestion(this.state)
    }

    setTria = async (elect)=>{
        await this.setState({
            tria: elect
        })

        this.updateEndorsementQuestion()
        this.toggleTria()
    }

    toggleTria = ()=>{
        this.setState({
            triaOpen: this.state.triaOpen ? false : true
        })
    }
        
    render(){
        const { applicationsContainer } = this.props
        const isValidating = applicationsContainer.state.isValidating        
        return(
            <div css={componentStyle}>
                 <div className="row">
                    <div className="col">
                        <h3>Additional Endorsement Questions</h3>
                        <p className="directions">
                            Please answer or complete each of the following questions as it applies to your request for coverage.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>1. Is a certificate to demonstrate financial assurance needed? <span className="required">*</span><br />
                            Defense Expense Limits must be selected as yes in order to obtain a certificate of insurance.<br />Please Note: Certificates cannot be issued for tanks located in Tennessee.</label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" 
                                    type="radio" 
                                    id="requestedCertificateForFinancialAssuranceYes" 
                                    name="requestedCertificateForFinancialAssurance"
                                    value="yes"
                                    checked={(applicationsContainer.state.endorsementQuestion1 && applicationsContainer.state.endorsementQuestion1.boolYesOnly) || this.state.requestedCertificateForFinancialAssurance === "yes"}
                                    onChange={this.updateValue}
                                    disabled={applicationsContainer.state.endorsementQuestion1 && applicationsContainer.state.endorsementQuestion1.boolNoOnly} />
                                <label className={"form-check-label" + ((isValidating && this.state.requestedCertificateForFinancialAssurance === null) ?" required" : "")}
                                    htmlFor="requestedCertificateForFinancialAssuranceYes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" 
                                    type="radio" 
                                    id="requestedCertificateForFinancialAssuranceNo"
                                    name="requestedCertificateForFinancialAssurance"
                                    value="no"
                                    checked={(applicationsContainer.state.endorsementQuestion1 && applicationsContainer.state.endorsementQuestion1.boolNoOnly) || this.state.requestedCertificateForFinancialAssurance === "no"}
                                    onChange={this.updateValue}
                                    disabled={applicationsContainer.state.endorsementQuestion1 && applicationsContainer.state.endorsementQuestion1.boolYesOnly} />
                                <label className={"form-check-label" + ((isValidating && this.state.requestedCertificateForFinancialAssurance === null) ?" required" : "")}
                                    htmlFor="requestedCertificateForFinancialAssuranceNo">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="error endorsementError">
                            {applicationsContainer.state.endorsementQuestion1 &&
                                applicationsContainer.state.endorsementQuestion1.message
                            }
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>2. Enter your additional named insureds below.</label>
                        </div>
                        <AdditionalInsureds applicationsContainer={applicationsContainer}
                                            type="Named" />
                    </div>
                </div>
                
                <div className="row margin-top">
                    <div className="col">
                        <div className="form-group">
                            <label>3. Enter your additional insureds below.</label>
                        </div>
                        <AdditionalInsureds applicationsContainer={applicationsContainer}
                                            type="Additional" />
                    </div>
                </div>
                
                <div className="row margin-top">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>4. Enter your notice of cancellation to a third party entities and addresses below.</label>
                        </div>
                        <Entities applicationsContainer={applicationsContainer} />
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label>
                                5. Would you like Terrorism coverage?<span className="required">*</span> 
                             </label>

                             <button className="btn btn-sm btn-primary tria"
                                onClick={this.toggleTria}>
                                Elect or Decline Terrorism Insurance Coverage
                            </button>

                            {applicationsContainer.state.tria !== null &&
                                <p className="electedStatus">{(applicationsContainer.state.tria === true || applicationsContainer.state.tria === "yes") ? "Elected" : "Declined"}</p>
                            }                            
                        </div>
                    </div>
                </div>

                {this.state.triaOpen &&
                    <Modal close={this.toggleTria}>
                        <div className="row">
                            <div className="col">
                            <h3 className="disclosure">Policy Disclosure Notice of Terrorism Insurance Coverage</h3>

                            <p>You are hereby notified that under the federal Terrorism Risk Insurance Act, as amended, you have the right to purchase insurance coverage for losses resulting from acts of terrorism, as defined therein. Generally, the term “act of terrorism” means any act that is certified by the U.S. Secretary of Treasury, in concurrence with the U.S. Secretary of Homeland Security and the U.S. Attorney General, to be an act of terrorism; to be a violent act or an act that is dangerous to human life, property or infrastructure; to have resulted in damage within the United States, or outside the United States in the case of certain air carriers or vessels or the premises of a United States mission; and to have been committed by an individual or individuals as part of an effort to coerce the civilian population of the United States or to influence the policy or affect the conduct of the United States Government by coercion. Where coverage is afforded for losses resulting from certified acts of terrorism, such losses may be partially reimbursed by the United States government under a formula established by federal law. Your policy may contain other exclusions which might affect your coverage.</p>

                            <h3>A. Disclosure of Premium</h3>
                            <p>In accordance with the federal Terrorism Risk Insurance Act, we are required to provide you with a notice disclosing the portion of your premium, if any, attributable to coverage for terrorist acts certified under the Terrorism Risk Insurance Act. The portion of your premium attributable to such coverage is shown in the in the policy Declarations.</p>

                            <h3>B. Disclosure of Federal Participation in Payment of Terrorism Losses</h3>
                            <p>The United States Government, Department of the Treasury, will pay a share of terrorism losses insured under the federal program. The federal share equals a percentage of that portion of the amount of such insured losses that exceeds the applicable insurer retention. Based on the most recent renewal of TRIA, the federal share percentage is set at 80%.</p>

                            <p>However, if aggregate insured losses attributable to terrorist acts certified under the Terrorism Risk Insurance Act exceed $100 billion in a calendar year, the Treasury shall not make any payment for any portion of the amount of such losses that exceeds $100 billion.</p>

                            <h3>C. Cap on Insurer Participation in Payment of Terrorism Losses</h3>
                            <p>If aggregate insured losses attributable to terrorist acts certified under the Terrorism Risk Insurance Act exceed $100 billion in a calendar year and the company has met its insurer deductible under the Terrorism Risk Insurance Act, the Company shall not be liable for the payment of any portion of the amount of such losses that exceeds $100 billion, and in such case insured losses up to that amount are subject to pro rata allocation in accordance with procedures established by the Secretary of the Treasury.</p>

                            <h3>Acceptance or Rejection of Terrorism Insurance Coverage</h3>
                            </div>
                        </div>

                        <div className="row tria-button-group">
                            <div className="col">                            
                                <button className="btn btn-sm btn-primary"
                                        onClick={()=>this.setTria(false)}>
                                    Decline
                                </button>
                                <button className="btn btn-sm btn-primary"
                                        onClick={()=>this.setTria(true)}>
                                    Elect
                                </button>
                            </div>
                        </div>               
                    </Modal>
                }
            </div> 
        )
    }   
}

export default EndorsementQuestions