/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import LocationsBindingQuestionRow from "./LocationsBindingQuestionRow"

const componentStyle = css`
    margin-top: 15px;

    th.use{
        width: 175px;
    }
    th.add{
        width: 150px;
    }
    th.city{
        width: 125px;
    }
    th.state{
        width: 75px;
    }
    th.zip{
        width: 125px;
    }
`
class LocationsBindingQuestionTable extends React.Component{

    render(){
        const { applicationsContainer, questionSet } = this.props
         
        return(
            <div css={componentStyle}>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th className="use">Site Use</th>
                            <th className="add">Street Address</th>
                            <th className="city">City</th>
                            <th className="state">State</th>
                            <th className="zip">Zip Code</th>
                            {questionSet.locations[0].questions.map((question, index)=>(
                                <th key={index}>
                                    {question.label}<span className="required">*</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {questionSet.locations.map((location, index)=>(
                            <LocationsBindingQuestionRow key={index}
                                                         applicationsContainer={applicationsContainer}
                                                         location={location} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }   
}

export default LocationsBindingQuestionTable



