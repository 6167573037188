import { sendGet, sendPost } from "./generic"

export const login = (payload)=>{
    return sendPost("Account/Login", payload)
}

export const loginWith2fa = (payload)=>{
    return sendPost("Account/LoginWith2fa", payload)
}

export const logout = (payload)=>{
    return sendPost("Account/Logout", payload)
}

export const refreshToken = (payload)=>{
    return sendPost("Account/Refresh", payload)
}

export const forgotPassword = (payload)=> {
    return sendPost("Account/ForgotPassword", payload)
}

export const resetPassword = (payload)=> {
    return sendPost("Account/ResetPassword", payload) 
}

// export const resend2faToken = ()=>{
//     return sendGet("Account/Send2faToken")
// }