import axios from "axios"
import { getBaseApiUrl } from "../../constants/Urls"

export const sendPost = async (url, body, baseUrl)=> {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        setHeader()
        var response = await axios.post(fullUrl, body)
        return handleResponse(response)
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

export const sendGet = async (url, baseUrl)=> {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        setHeader()
        var response = await axios.get(fullUrl)
        return handleResponse(response)
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

export const sendGetFile = async (url, baseUrl)=> {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        setHeader()
        var response = await axios.get(fullUrl, {responseType: "blob"})
        return response
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

export const uploadFile = async (url, file, baseUrl) => {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        var formData = new FormData()
        formData.append("file", file)
        var response = await axios.post(fullUrl, formData)
        return handleResponse(response)
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

export const sendPut = async (url, body, baseUrl)=> {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        setHeader()
        var response = await axios.put(fullUrl, body)
        return handleResponse(response)
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

export const sendDelete = async (url, body, baseUrl) => {
    var fullUrl = (baseUrl === undefined ? getBaseApiUrl() : baseUrl) + url
    try {
        setHeader()
        var response = await axios.delete(fullUrl, {data: body})
        return handleResponse(response)
    }
    catch (error) {
        return handleResponse(error.response)
    }
}

const setHeader = ()=>{
    const accessToken = sessionStorage.getItem("ACCESS_TOKEN")

    axios.defaults.withCredentials = false
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
    if(accessToken !== undefined) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
    }
}

// maps an axios response to a result
const handleResponse = (response)=> {

    if(response?.status === 401){
        window.location = "/login"
    }

    if (response?.status === 200 || response?.status === 204) {
        if(response.data === undefined) {
            return response
        }

        return response.data
    }
}