import { sendGet, sendPost } from "./generic"

export const getLocationSites = ()=> {
    return sendGet("Locations/Sites")
}

export const updateLocation = (locationId, payload)=> {
    return sendPost("Locations/"+locationId, payload)
}

export const reorderLocations = (payload)=> {
    return sendPost("Locations/Reorder", payload)
}

