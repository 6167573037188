/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import BindingQuestionField from "./BindingQuestionField"

const componentStyle = css`
`

class LocationsBindingQuestionRow extends React.Component{

    render(){
        const { applicationsContainer, location } = this.props
        const valuesMap = applicationsContainer.state.valuesMap

        return(            
            <tr css={componentStyle}>
                <td>{valuesMap.get(location.siteUse)}</td>
                <td>{location.address}</td>
                <td>{location.city}</td>
                <td>{location.state}</td>
                <td>{location.postalCode}</td>
                {location.questions.map((question, index)=>(
                    <td key={index}>
                        <BindingQuestionField applicationsContainer={applicationsContainer}
                                              question={question}
                                              item={location} />
                    </td>
                ))}
            </tr>
        )
    }   
}

export default LocationsBindingQuestionRow