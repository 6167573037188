/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { sortableHandle } from "react-sortable-hoc"

const DragHandle = sortableHandle(() => {
    return(
        <i className="fas fa-arrows-alt" />
    )
})

const componentStyle = css`
    i{
        cursor: pointer;
    }
`
class Location extends React.Component{

    state ={
        id: "",
        siteUse: "None",
        address: "",
        city: "",
        state: "None",
        locationNumber: 0,
        otherSiteUse: "",
        postalCode: ""
    }

    componentDidMount(){
        const { location, applicationsContainer } = this.props
        const loc = applicationsContainer.state.locationsMap.get(location)

        this.setState({
            id: loc.id,
            siteUse: loc.siteUse ? loc.siteUse : "None",
            address: loc.address,
            city: loc.city,
            state: loc.state ? loc.state : "None",
            locationNumber: loc.locationNumber,
            otherSiteUse: loc.otherSiteUse,
            postalCode: loc.postalCode
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        await this.setState({
            [e.name]: e.value
        })

        if(e.name === "siteUse" && e.value !== "Other"){
            await this.setState({
                otherSiteUse: ""
            })
        }

        if(e.name === "siteUse" || e.name === "state"){
            this.updateLocation()
        }
    }

    updateLocation = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateLocation(this.state)
    }

    deleteLocation = async (number, id)=>{
        const { applicationsContainer } = this.props
        let confirm = window.confirm("Are you sure you want to delete location " + number + "?")
        if(confirm){
           await applicationsContainer.deleteLocations([id])
        }
    }
        
    render(){
        const { applicationsContainer } = this.props
        const isValidating = applicationsContainer.state.isValidating

        return(            
            <tr css={componentStyle}>
                <td className="action"><DragHandle /></td>
                <td>{this.state.locationNumber}</td>
                <td>
                    <div className="form-group">
                        <select className={"form-control form-control-sm" + ((isValidating && this.state.siteUse === "None") ? " required" : "")}
                                name="siteUse"
                                value={this.state.siteUse}
                                onChange={this.updateValue}>                            
                            <option value="None">--- Select ---</option>
                            {applicationsContainer.state.locationSites.map(locationSite=>(
                                (locationSite.id !== "None" &&
                                    <option key={locationSite.id}
                                            value={locationSite.id}>
                                        {locationSite.name}
                                    </option>
                                )                                
                            ))}
                        </select>
                    </div>
                    {this.state.siteUse === "Other" &&
                        <div className="form-group">
                            <input type="text" 
                                    className={"form-control form-control-sm" + ((isValidating && this.state.otherSiteUse === "") ? " required" : "")}
                                    id="otherSiteUse" 
                                    name="otherSiteUse"
                                    placeholder="Enter Other Site Use"
                                    value={this.state.otherSiteUse}
                                    onChange={this.updateValue}
                                    onBlur={this.updateLocation} />
                        </div>   
                    }                    
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.address === "") ? " required" : "")}
                               id="address" 
                               name="address"
                               value={this.state.address}
                               onChange={this.updateValue}
                               onBlur={this.updateLocation} />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.city === "") ? " required" : "")} 
                               id="city" 
                               name="city"
                               value={this.state.city}
                               onChange={this.updateValue}
                               onBlur={this.updateLocation} />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <select className={"form-control form-control-sm" + ((isValidating && this.state.state === "None") ? " required" : "")}
                                id="state"
                                name="state"
                                value={this.state.state}
                                onChange={this.updateValue}>                            
                            <option value="None">--- Select ---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.postalCode === "") ? " required" : "")}
                               id="postalCode" 
                               name="postalCode"
                               value={this.state.postalCode}
                               onChange={this.updateValue}
                               onBlur={this.updateLocation} />
                    </div>
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                       onClick={()=>{this.deleteLocation(this.state.locationNumber, this.state.id)}} />
                </td>
            </tr>
        )
    }   
}

export default Location